import { useFetch, useRuntimeConfig } from '#app'

type useFetchType = typeof useFetch
// wrap useFetch with configuration needed to talk to our API
export const useAPIFetch: useFetchType = (path, options = {}) => {
  const config = useRuntimeConfig()
  // modify options as needed
  const nuxtApp = useNuxtApp()
  options.baseURL = config.public.baseURL
  options.headers = {
    ...options.headers,
    // @ts-ignore
    'Accept-Language': !options['Accept-Language']
      ? (nuxtApp.$i18n as any).locale.value === 'en'
        ? 'en-US'
        : 'ka'
      : 'en-US',
  }
  return useFetch(path, options)
}
